import React, { useState, useEffect } from 'react';
import './SelectableButtons.css'; // Import a CSS file for styling (you can create this file)
import { useGoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import axios from 'axios';

function SocialIcons() {
  const [authProgress, setAuthProgress] = useState(0);
  const [pointPosition, setPointPosition] = useState({ top: 120, left: 180 });
  const [showGoogleOAuth, setShowGoogleOAuth] = useState(true);
  const [user, setUser] = useState({});

  const socialMediaImages = {
    Shopify: require('./Images/shopify.png'),
    Google: require('./Images/google.png'),
    GoogleAnalytics: require('./Images/googleanalytics.png'),
    Meta: require('./Images/meta.png'),
    Facebook: require('./Images/facebook.png'),
    Instagram: require('./Images/instagram.png'),
    Snapchat: require('./Images/snapchat.png'),
    Finish: require('./Images/finish.png'),
  };

  const socialMediaOrder = [
    'Shopify',
    'Google',
    'GoogleAnalytics',
    'Meta',
    'Facebook',
    'Instagram',
    'Snapchat',
    'Finish'
  ];

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setAuthProgress(authProgress + 1);
      setUser(codeResponse);

      // Update the point position for the next icon
      updatePointPosition(authProgress + 1);
      console.log(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const updatePointPosition = (index) => {
    if (index < socialMediaOrder.length) {
      const nextSocialMedia = socialMediaOrder[index];
      const nextIconElement = document.querySelector(`.icon.${nextSocialMedia.toLowerCase()}-icon`);
      const nextIconPosition = nextIconElement.getBoundingClientRect();
  
      // Create a temporary element to measure the dimensions of the point
      const tempPointElement = document.createElement('div');
      tempPointElement.className = 'point'; // Add the class name of your point element
      document.body.appendChild(tempPointElement);
  
      // Get the dimensions of the temporary point element
      const pointHeight = tempPointElement.offsetHeight;
      const pointWidth = tempPointElement.offsetWidth;
  
      // Remove the temporary point element
      document.body.removeChild(tempPointElement);

      if(index===0)
  { 
    console.log(index)
    setPointPosition({
    top: nextIconPosition.top + nextIconPosition.height / 2 - pointHeight / 2,
    left:180,
  });}

  if(index===1)
  { 
    console.log(index)
    setPointPosition({
    top: nextIconPosition.top + nextIconPosition.height / 2 - pointHeight / 2,
    left:280,
  });}

  if(index===2)
  { 
    console.log(index)
    setPointPosition({
    top: nextIconPosition.top + nextIconPosition.height / 2 - pointHeight / 2,
    left:200,
  });}

  if(index===3)
  { 
    console.log(index)
    setPointPosition({
    top: nextIconPosition.top + nextIconPosition.height / 2 - pointHeight / 2,
    left:280,
  });}

  if(index===4)
  { 
    console.log(index)
    setPointPosition({
    top: nextIconPosition.top + nextIconPosition.height / 2 - pointHeight / 2,
    left:200,
  });}

  if(index===5)
  { 
    console.log(index)
    setPointPosition({
    top: nextIconPosition.top + nextIconPosition.height / 2 - pointHeight / 2,
    left:280,
  });}

  if(index===6)
  { 
    console.log(index)
    setPointPosition({
    top: nextIconPosition.top + nextIconPosition.height / 2 - pointHeight / 2,
    left:200,
  });}
     
     
    }
  };
  

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          // setProfile(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  useEffect(() => {
    if (socialMediaOrder[authProgress] === 'Google') {
      openGoogleOAuthProvider();
    }
  }, [authProgress]);

  const openGoogleOAuthProvider = () => {
    const openGoogleOAuthProvider = () => {
      // Update the point position for the Google icon
      updatePointPosition(authProgress);
    
      // Get the button element for Google login
      const googleLoginButton = document.querySelector('.icon.google-icon');
    
      // Get the position of the Google login button
      const buttonPosition = googleLoginButton.getBoundingClientRect();
    
      // Calculate the desired position for the pop-up (adjust as needed)
      const popupTop = buttonPosition.top;
      const popupLeft = buttonPosition.left + buttonPosition.width + 10; // Add some padding to the right
    
      // Set the position for the Google OAuth provider pop-up
      const googleOAuthContainer = document.querySelector('.google-oauth-container'); // Adjust this selector based on your actual structure
      googleOAuthContainer.style.position = 'absolute';
      googleOAuthContainer.style.top = `${popupTop}px`;
      googleOAuthContainer.style.left = `${popupLeft}px`;
    
      // Show the Google OAuth provider container
      setShowGoogleOAuth(true);
    };
    
    setShowGoogleOAuth(true);
  };

  return (
    <>
      <div style={{ paddingLeft: '50px', paddingTop: '20px' }}>
        <h2 className='cds'>Connect Data Sources</h2>
        <h6 className='finishline'>Get to the finish line with as many green connections to ensure data accuracy</h6>
      </div>
      <div className="icon-grid zigzag-icons-container">
        {socialMediaOrder.map((socialMedia, index) => (
          <div
            key={index}
            className={`icon  frequency-icon ${socialMedia.toLowerCase()}-icon  `}
            // className={`icon  frequency-icon ${socialMedia.toLowerCase()}-icon   ${index === authProgress ? 'active' : ''}`}
            style={{
               
              marginLeft: index === 0 ? '20px' : index === 1 ? '100px' : index === 2 ? '20px' : index === 3 ? '100px' : index === 4 ? '20px' : index === 5 ? '100px' : index === 6 ? '30px' : '0'
            }}
          >
          <div
          className={`point ${authProgress < socialMediaOrder.length - 1 ? 'active' : ''}`}
          style={{
            top: `${pointPosition.top}px`,
            left: `${pointPosition.left}px`,
        
            transition: 'top 2.3s ease-in-out, left 2.3s ease-in-out', 
          }}
        >
          
        </div>

            <img
              src={socialMediaImages[socialMedia]}
              alt={socialMedia}
              onClick={() => login()}
              className={`social-media-image ellipse ${socialMedia}`} // Add a CSS class based on the social media platform
            />
          

          </div>
        ))}

        
      </div>
    </>
  );
}

export default SocialIcons;
