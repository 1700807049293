import React, { useState } from "react";
import Girl from "../images/Girl.png";

export default function UserIntro({ onNext }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [companyError, setCompanyError] = useState("");


  const handleValidation = () => {
    setFirstNameError("");
    setLastNameError("");
    setCompanyError("");

    let isValid = true;

    if (firstName.trim() === "") {
      setFirstNameError("First Name is required *");
      isValid = false;
    }

    if (lastName.trim() === "") {
      setLastNameError("Last Name is required *");
      isValid = false;
    }

    if (company.trim() === "") {
      setCompanyError("Company is required *");
      isValid = false;
    }


    if (isValid) {
      onNext();
    }
  };

  return (
    <div className="row vh-100">
      <div className="col-7">
        <h2 className="h2design">Tell us a bit more about yourself</h2>

        <div className="input-container">
          <label>
            First Name
            <input
              required
              value={firstName}
              className="input-style"
              onChange={(e) => setFirstName(e.target.value)}
            />
            <div className="error-message">{firstNameError}</div>
          </label>
        </div>

        <div className="input-container">
          <label>
            Last Name
            <input
              className="input-style"
              required
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <div className="error-message">{lastNameError}</div>
          </label>
        </div>

        <div className="input-container">
          <label>
            Your Company
            <input
              required
              className="input-style"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
            <div className="error-message">{companyError}</div>
          </label>
        </div>
      </div>

      <div className="col-5 image-container">
        <img src={Girl} alt="Girl" className="girl" />
      </div>

      <button
        id="nextBtn"
        onClick={handleValidation}
        style={{ display: "none" }}
      >
        Next
      </button>
    </div>
  );
}
