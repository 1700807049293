import React, { useState } from 'react';
import { BellFilled } from "@ant-design/icons";
import { Avatar } from "antd";
import { Input } from 'antd';
import Dashboard from '../../Icons/Dashboard.png'
import user from '../../Icons/user.png'
import usergear from '../../Icons/usergear.png'
import plug from '../../Icons/plug.png'
import users from '../../Icons/users.png'
import headset from '../../Icons/headset.png'
import creditcardfront from '../../Icons/creditcardfront.png'
import fileuser from '../../Icons/fileuser.png'
import bells from '../../Icons/bells.png'
import './Admin.css'
import {
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  SearchOutlined,
  AppstoreOutlined ,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Layout, Menu, Button, theme ,  Tooltip  } from 'antd';
import Analytics from '../Analytics'
const { Header, Sider, Content } = Layout;

const { Item, SubMenu } = Menu;
const App = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [selectedMenuItem, setSelectedMenuItem] = useState('1');

  const handleMenuClick = (key) => {
    setSelectedMenuItem(key);
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();



  const handleSubMenuHover = () => {
    if (collapsed) {
      setCollapsed(false);
    }
  };

  const handleSubMenuLeave = () => {
    if (!collapsed) {
      setCollapsed(true);
    }
  };
 

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ backgroundColor: '#2B68AD', position: 'fixed', height: '100%' }}
      >
        <div className="demo-logo-vertical" />
        <Menu
         onMouseEnter={handleSubMenuHover}
         onMouseLeave={handleSubMenuLeave}
          theme="#2B68AD"
          mode="inline"
          color='white'
          defaultSelectedKeys={["1"]}
          selectedKeys={[selectedMenuItem]}
          onClick={({ key }) => handleMenuClick(key)}
          style={{ backgroundColor: '#2B68AD', color: 'white' }}
        >
            <svg
  xmlns="http://www.w3.org/2000/svg"
  width="35"
  height="32"
  className='dt'
  style={{ margin: "5px auto", marginTop:"20px", display: "block" }}
  viewBox="0 0 35 32"
  fill="none"
  onClick={() => setCollapsed(!collapsed)}

  
>
  <path
    d="M1 30.5C12.2113 27.974 5.52192 2.0716 17 1.5C28.5918 0.922731 22.2352 27.7056 33.5 30.5"
    stroke="white"
    stroke-width="2"
    stroke-linecap="round"
  />
</svg>

<hr style={{ width: "170px", margin: "11px auto", height: "1px", opacity: "0.3" }} />


<SubMenu key="1" title="Dashboards" icon={<AppstoreOutlined style={{ fontSize: "17px" , marginLeft: collapsed ? '1px' : '-4px' }} />} style={{  color: 'white'   }}>
        <Menu.Item key="12" style={{color:"white"}}>Dashboard 1</Menu.Item>
        <Menu.Item key="13" style={{color:"white"}}>Dashboard 2</Menu.Item>
        <Menu.Item key="14" style={{color:"white"}}>Dashboard 3</Menu.Item>

</SubMenu>


<SubMenu  title="Profile"  key="2" icon={<i className="fa-light fa-user" style={{ marginLeft: collapsed ? '1px' : '-4px'}} ></i>} style={{ color: 'white' }}>
        <Menu.Item key="21" style={{color:"white"}}>Account Settings</Menu.Item>
        <Menu.Item key="22" style={{color:"white"}}>Payment Plan</Menu.Item>
        <Menu.Item key="23" style={{color:"white"}}>Notification Preference</Menu.Item>
</SubMenu>
<Menu.Item key="3" icon={<i className="fa-thin fa-head-side-gear"></i>}  style={{ color: 'white' }}>
  User Management
</Menu.Item>

<Menu.Item key="4"  icon={<i className="fa-regular fa-plug"></i>} style={{ color: 'white' }}>
Source Connectors
</Menu.Item>
<Menu.Item  key="5" icon={<i className="fa fa-users" aria-hidden="true"></i>} style={{ color: 'white' }}>
  Community
</Menu.Item>
<Menu.Item key="6" icon={<i className="fa-solid fa-headphones-simple"></i>}  style={{ color: 'white' }}>
  Support
</Menu.Item>



        </Menu>
      </Sider>

      <Layout style={{ marginLeft: collapsed ? '80px' : '200px' }}>
    
      <Header
  style={{
    padding: 0,
    background: colorBgContainer,
    color: 'black',
    position: 'fixed',
    width: '100%',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'space-between', // or 'flex-end'
  }}
>
  {selectedMenuItem && (
    <div style={{ marginLeft: 16 }} className='headline'>
      {selectedMenuItem === '1' && (
        <>
         <img src={Dashboard} alt="dashboard" style={{marginRight:"7px"}}></img>
          Dashboards
        </>
      )}
      {selectedMenuItem === '2' && (
        <>
      <img src={user} alt='user' style={{marginRight:"7px"}}></img>
         Profile
        </>
      )}
      {selectedMenuItem === '3' && (
        <>
       <img src={usergear} alt='user' style={{marginRight:"7px"}}></img>
        User Management
        </>
      )}
       {selectedMenuItem === '4' && (
        <>
        <img src={plug} alt='user' style={{marginRight:"7px"}}></img>
        Source Connector
        </>
      )}
       {selectedMenuItem === '5' && (
        <>
        <img src={users} alt='user' style={{marginRight:"7px"}}></img>
      Community 
        </>
      )}
       {selectedMenuItem === '6' && (
        <>
         <img src={headset} alt='user' style={{marginRight:"7px"}}></img>
      Support
        </>
      )}
      {selectedMenuItem === '12' && (
        <>
          <img src={Dashboard} alt="dashboard" style={{marginRight:"7px"}}></img>
      Dashboards &nbsp; <p className='dashboards-heading'>/ Dashboard1</p> 
        </>
      )}
      {selectedMenuItem === '13' && (
        <>
         <img src={Dashboard} alt="dashboard" style={{marginRight:"7px"}}></img>
      Dashboards &nbsp; <p className='dashboards-heading'>/ Dashboard2</p> 
        </>
      )}
      {selectedMenuItem === '14' && (
        <>
          <img src={Dashboard} alt="dashboard" style={{marginRight:"7px"}}></img>
      Dashboards &nbsp; <p className='dashboards-heading'>/ Dashboard3</p> 
        </>
      )}

{selectedMenuItem === '21' && (
        <>
          <img src={Dashboard} alt="dashboard" style={{marginRight:"7px"}}></img>
      Dashboards / &nbsp; <img src={fileuser} alt="dashboard" style={{marginRight:"7px" , height:"20px"}}></img> <p className='dashboards-heading'> Account Settings</p> 
        </>
      )}
      {selectedMenuItem === '22' && (
        <>
         <img src={Dashboard} alt="dashboard" style={{marginRight:"7px"}}></img>
      Dashboards / &nbsp; <img src={creditcardfront} alt="dashboard" className='top-sub-icons' style={{marginRight:"7px"}}></img> <p className='dashboards-heading'> Payment Plan</p> 
        </>
      )}
      {selectedMenuItem === '23' && (
        <>
          <img src={Dashboard} alt="dashboard" style={{marginRight:"7px"}}></img>
      Dashboards / &nbsp; <img src={bells} alt="dashboard" className='top-sub-icons' style={{marginRight:"7px"}}></img> <p className='dashboards-heading'>Notification Preferences</p> 
        </>
      )}

    </div>
  )}


<div style={{marginLeft: collapsed ? '300px' : '200px'}}><Input style={{width:"550px", borderRadius:"0px", }} size="large" placeholder="Search for anything" prefix={<SearchOutlined style={{fontSize:"18px"}}/>} /></div>
  <div style={{ display: 'flex', marginRight: collapsed ? '110px' : '230px'  }}>
  <Avatar
  size={35}
  style={{
    background: 'white',
    marginTop: '17px',
    marginLeft: '8px',
    cursor: 'pointer',
    color: 'grey',
  }}
  icon={<BellFilled style={{ fontSize: '24px' }} />}
/>



    <Avatar
      size={35}
      style={{
        background: '#2EB67D',
        marginTop: '17px',
        marginLeft: '8px',
        cursor: 'pointer',
      }}
      icon={<UserOutlined  style={{fontSize:"18px"}}/>}
    />
  </div>
</Header>


        <div style={{ marginTop: '64px', padding: '24px' }}>
          <Content
            style={{
              background: colorBgContainer,
              padding: 15,
              minHeight: '80vh',
            }}
          >
{/* <Analytics/> */}
          </Content>
          
        </div>
      </Layout>
    </Layout>
  );
};

export default App;
