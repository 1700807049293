import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import DataFrequencyDesign from './DataFrequencyDesign'
export default function DataFrequency() {
  return (
    <div>
      <GoogleOAuthProvider clientId="978459741474-tlc80vcu4ft3bb3gik973q6rq9g32d9j.apps.googleusercontent.com">
      <DataFrequencyDesign />
    </GoogleOAuthProvider>
    </div>
  )
}