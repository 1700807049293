import React, { useState } from 'react';
import { Steps, Button , Row,Col } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './Step.css'; // Import custom CSS file for styling
import Compareplan from './Compareplan';

const { Step } = Steps;

const steps = [
  { title: 'Basic Information' },
  { title: 'Choose Plan' },
  {title:'Payment Confirmation'},
  { title: 'Connect Data Sources' },
  { title: 'Data Frequency' }
];

const App = (props) => {
  const [currentStep, setCurrentStep] = useState(2);

  const { heading } = props;

  const buttonStyle = {
    color: 'white !important'
  };

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  

  };

  const handlePrev = () => {
    setCurrentStep((prevStep) => prevStep - 1);
   
  };

  return (
    <>
    <Row>
      <Col span={4}>
      <div className='stepbody' style={{ display: 'flex', alignItems: 'center', height: '100vh', paddingLeft:"35px", color:"white" }}>
      <div style={{ width: '200px' }}>
      <h3 className='stepheading'>We offer something for everyone choose the plan that speaks your business{heading}</h3>
        <Steps
          current={currentStep-1}
          direction="vertical"
          size="small"
          className="custom-step-title"
        >
          {steps.map((step, index) => (
            <Step
              key={index}
              title={step.title}
              
              className={index <= currentStep ? 'custom-step-current' : ''}
              // description={index < currentStep ? 'Verified' : ''}
            />
          ))}
        </Steps>
        <div style={{ marginTop: '20px' }}>{steps[currentStep].content}</div>
        <div
          style={{ marginTop: '180px', display: 'flex', justifyContent: 'space-between' }}
        >
          {currentStep > 0 && (
            <Button className='prebutton' 
            // onClick={handlePrev}
            >
              <span className="button-icon">
                <ArrowLeftOutlined />
              </span>
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button style={buttonStyle} className='nextbutton'  disabled
            // onClick={handleNext}
            >
              Next
            </Button>
          )}
        </div>
      </div>
    </div>
      </Col>
      <Col span={20}>
     { <Compareplan/>}

   
      </Col>
    </Row>
    
    </>
    
  );
};

export default App;
