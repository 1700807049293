// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formInput{
  display: flex;
  flex-direction: column;
  width: 280px;
 
}

input{
  padding: 15px;
  width: 100%;
  margin: 10px 0px;
  border-radius: 5px;
  border: 1px solid rgb(137, 201, 224);
}


label{
  font-size: 12px;
  color:#5d3f85;
  font-weight: bold;
}

.span{
  font-size: 12px;
  padding: 3px;
  /* color: grey; */
  display: none;
}

input:invalid[focused="true"]{
  border: 1px solid red;
}

input:invalid[focused="true"] + .span{
  display: block;
}

.input-container {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  right: -40px; /* Adjust the right position as needed */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/formInput.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;;AAEd;;AAEA;EACE,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,oCAAoC;AACtC;;;AAGA;EACE,eAAe;EACf,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,YAAY,EAAE,wCAAwC;EACtD,QAAQ;EACR,2BAA2B;EAC3B,eAAe;AACjB","sourcesContent":[".formInput{\n  display: flex;\n  flex-direction: column;\n  width: 280px;\n \n}\n\ninput{\n  padding: 15px;\n  width: 100%;\n  margin: 10px 0px;\n  border-radius: 5px;\n  border: 1px solid rgb(137, 201, 224);\n}\n\n\nlabel{\n  font-size: 12px;\n  color:#5d3f85;\n  font-weight: bold;\n}\n\n.span{\n  font-size: 12px;\n  padding: 3px;\n  /* color: grey; */\n  display: none;\n}\n\ninput:invalid[focused=\"true\"]{\n  border: 1px solid red;\n}\n\ninput:invalid[focused=\"true\"] + .span{\n  display: block;\n}\n\n.input-container {\n  position: relative;\n}\n\n.password-toggle-icon {\n  position: absolute;\n  right: -40px; /* Adjust the right position as needed */\n  top: 50%;\n  transform: translateY(-50%);\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
