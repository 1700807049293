// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headline {
    color: var(--dark-purple, #140054);
    font-family: 'Open Sans', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: auto;
    display: flex;
    align-items: center; /* Vertically center the content */
  }
  
.dt{
    text-align: center;
    margin-top: 20px;
    justify-content: center;
   margin-left: 22px;
   cursor: pointer;
   margin: "5px auto",
}
.left-menu{
    color: white;
 
 
}
.menu-icon{
font-size: 40px;
color: white;
}

  .ant-tooltip {
    display: none;
  }


  /* .ant-tooltip-inner {
    background-color: #2B68AD !important;
    color: rgb(255, 255, 255) !important;
    font-weight: 500 !important;
  } */


  .custom-submenu[aria-expanded="false"] > .ant-menu-submenu-title::after {
    display: none !important;
  }
  
  

  .dashboards-heading{
    color: var(--text, #718096);
font-family: Open Sans;
font-size: 18px;
font-style: normal;
font-weight: lighter;
line-height: normal;
  }

  .top-sub-icons{
    width: 25px;
    height: 19px;
  }`, "",{"version":3,"sources":["webpack://./src/components/Admin/Admin.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,oCAAoC;IACpC,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,mBAAmB,EAAE,kCAAkC;EACzD;;AAEF;IACI,kBAAkB;IAClB,gBAAgB;IAChB,uBAAuB;GACxB,iBAAiB;GACjB,eAAe;GACf;AACH;AACA;IACI,YAAY;;;AAGhB;AACA;AACA,eAAe;AACf,YAAY;AACZ;;EAEE;IACE,aAAa;EACf;;;EAGA;;;;KAIG;;;EAGH;IACE,wBAAwB;EAC1B;;;;EAIA;IACE,2BAA2B;AAC/B,sBAAsB;AACtB,eAAe;AACf,kBAAkB;AAClB,oBAAoB;AACpB,mBAAmB;EACjB;;EAEA;IACE,WAAW;IACX,YAAY;EACd","sourcesContent":[".headline {\n    color: var(--dark-purple, #140054);\n    font-family: 'Open Sans', sans-serif;\n    font-size: 22px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n    margin: auto;\n    display: flex;\n    align-items: center; /* Vertically center the content */\n  }\n  \n.dt{\n    text-align: center;\n    margin-top: 20px;\n    justify-content: center;\n   margin-left: 22px;\n   cursor: pointer;\n   margin: \"5px auto\",\n}\n.left-menu{\n    color: white;\n \n \n}\n.menu-icon{\nfont-size: 40px;\ncolor: white;\n}\n\n  .ant-tooltip {\n    display: none;\n  }\n\n\n  /* .ant-tooltip-inner {\n    background-color: #2B68AD !important;\n    color: rgb(255, 255, 255) !important;\n    font-weight: 500 !important;\n  } */\n\n\n  .custom-submenu[aria-expanded=\"false\"] > .ant-menu-submenu-title::after {\n    display: none !important;\n  }\n  \n  \n\n  .dashboards-heading{\n    color: var(--text, #718096);\nfont-family: Open Sans;\nfont-size: 18px;\nfont-style: normal;\nfont-weight: lighter;\nline-height: normal;\n  }\n\n  .top-sub-icons{\n    width: 25px;\n    height: 19px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
