import React, { useState } from 'react';
import man from './man.png';

const SelectableButtons : React.FC = () => {
  const [selectedButton, setSelectedButton] = useState<number|null>(null);

  const handleButtonClick = (buttonIndex : number) => {
    // Check if the clicked button is already selected
    if (selectedButton === buttonIndex) {
      // Deselect the button by setting selectedButton to null
      setSelectedButton(null);
    } else {
      // Select the button by setting selectedButton to the clicked button's index
      setSelectedButton(buttonIndex);
    }
  };

  const buttons = ['HOURLY', 'DAILY', 'WEEKLY', 'MONTHLY'];

  return (
    <div>
      <h1 className="frequency-heading">Choose the frequency of your data</h1>

      <div className="button-container">
        {buttons.map((button, index) => (
          <button
            key={index}
            className={`buttonstyle selectable-button ${selectedButton === index ? 'selected' : ''}`}
            onClick={() => handleButtonClick(index)}
          >
            {button}
          </button>
        ))}
      </div>

      <div style={{ float: 'right' }}>
        <img src={man} style={{ height: "520px", width: "470px" }} alt="Man" />
      </div>
    </div>
  );
}

export default SelectableButtons;
