// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
  display: flex; /* Display children in a row */
  padding-top: 30px;
  padding-left: 20px;
  margin-left: 25px;
}

@media screen and (max-width: 768px) {
  .button-container {
    padding-left: 50px;
  }

  .frequency-heading {
    padding-left: 50px;
  }
  
}

.frequency-heading{
  
  padding-top: 20px;
  padding-left: 20px;
  text-align: left;
  margin-left: 25px;
}
  
  .buttonstyle{
    height: 45px;
    width: 140px;
  }
.selectable-button {
    border-radius: 3px;
    border: 1px solid #38ABD2;
    background-color: #fff;
   
    margin: 10px;
    color: #38ABD2; /* Text color */
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition */

    text-align: center;
font-family: Open,sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 24px; /* 150% */
text-transform: uppercase;
  }
  
  .selectable-button:hover {
    background-color: #38ABD2;
    color: white;
  }
  
  .selectable-button.selected {
    background-color: #38ABD2;
    color: white;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Selectingfrequency/SelectingFrequency.css"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAE,8BAA8B;EAC7C,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE;IACE,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB;;AAEF;;AAEA;;EAEE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;EAEE;IACE,YAAY;IACZ,YAAY;EACd;AACF;IACI,kBAAkB;IAClB,yBAAyB;IACzB,sBAAsB;;IAEtB,YAAY;IACZ,cAAc,EAAE,eAAe;IAC/B,eAAe;IACf,iCAAiC,EAAE,sBAAsB;;IAEzD,kBAAkB;AACtB,4BAA4B;AAC5B,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,iBAAiB,EAAE,SAAS;AAC5B,yBAAyB;EACvB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd","sourcesContent":[".button-container {\n  display: flex; /* Display children in a row */\n  padding-top: 30px;\n  padding-left: 20px;\n  margin-left: 25px;\n}\n\n@media screen and (max-width: 768px) {\n  .button-container {\n    padding-left: 50px;\n  }\n\n  .frequency-heading {\n    padding-left: 50px;\n  }\n  \n}\n\n.frequency-heading{\n  \n  padding-top: 20px;\n  padding-left: 20px;\n  text-align: left;\n  margin-left: 25px;\n}\n  \n  .buttonstyle{\n    height: 45px;\n    width: 140px;\n  }\n.selectable-button {\n    border-radius: 3px;\n    border: 1px solid #38ABD2;\n    background-color: #fff;\n   \n    margin: 10px;\n    color: #38ABD2; /* Text color */\n    cursor: pointer;\n    transition: background-color 0.3s; /* Smooth transition */\n\n    text-align: center;\nfont-family: Open,sans-serif;\nfont-size: 14px;\nfont-style: normal;\nfont-weight: 700;\nline-height: 24px; /* 150% */\ntext-transform: uppercase;\n  }\n  \n  .selectable-button:hover {\n    background-color: #38ABD2;\n    color: white;\n  }\n  \n  .selectable-button.selected {\n    background-color: #38ABD2;\n    color: white;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
