import React, { useState } from 'react';
import { Row, Col } from 'antd';
import Girl from '../Girl.png';
import './UserIntro.css';
import { useNavigate } from 'react-router-dom';
import { Steps, Button  } from 'antd';
import './Step.css'; // Import custom CSS file for styling


const { Step } = Steps;


const steps = [
  { title: 'Basic Information' },
  { title: 'Choose Plan' },
  {title:'Payment Confirmation'},
  { title: 'Connect Data Sources' },
  { title: 'Data Frequency' }
];

const stepDescriptions = [
  'Hello! Welcome to Datatram',
  'Hello! Welcome to Datatram',
  'We offer something for everyone choose the plan that speaks your business',
  'We offer something for everyone choose the plan that speaks your business',
  'Almost there. Connect to as many data sources as possible.',
  'We can see the finish line. Can you?',
 
];

export default function UserIntro() {
  const navigate = useNavigate();

  const buttonStyle = {
    color: 'white !important',
  };

  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = () => {

    navigate('/WelcomeToDatatram/paymentplans');
  };
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [companyError, setCompanyError] = useState('');

  const handleValidation = () => {
    // Initialize error messages
    setFirstNameError('');
    setLastNameError('');
    setCompanyError('');

    // Check for empty fields and display error messages
    if (firstName.trim() === '') {
      setFirstNameError('First Name is required *');
    }
   else if (lastName.trim() === '') {
      setLastNameError('Last Name is required *');
    }
   else if (company.trim() === '') {
      setCompanyError('Company is required *');
    }
else
return handleNext()

  };

  return (
    <form >
    <Row >
      
      <Col span={4}>
      <div className='stepbody' style={{ display: 'flex', alignItems: 'center', height: '100vh', paddingLeft:"35px", color:"white" }}>
      <div style={{ width: '200px' }}>
      <h3 className='stepheading'> {stepDescriptions[currentStep]}</h3>
        <Steps
          // current={currentStep-1}
          direction="vertical"
          size="small"
        style={{fontSize:"10px"}}
      
          className="custom-step-title span"
         
        >
       
          {steps.map((step, index) => (
            <Step
              key={index}
              title={step.title}
            
              className={ index <= currentStep ? 'custom-step-current' : ''}
              // description={index < currentStep ? 'Verified' : ''}
            />
          ))}
        </Steps>
        {/* <div style={{ marginTop: '20px' }}>{steps[currentStep].content}</div> */}
        <div
          style={{ marginTop: '180px', display: 'flex', justifyContent: 'space-between' }}
        >
        
          {currentStep < steps.length && (
            <Button style={buttonStyle} type='submit' className='nextbutton'   onClick={() => {
           
       handleValidation();
          
            }}>
              Next
            </Button>
          )
          
          }
         
        </div>
      </div>
    </div>
      </Col>


      <Col span={12} style={{paddingLeft:"50px"}}>
        <h2 className="h2design">Tell us a bit more about yourself</h2>
        <br />
        <br />
      
          <label>
            First Name
            <input
              required
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            ></input>
            <div className="error-message">{firstNameError}</div>
          </label>
          <br />
          <label>
            Last Name
            <input
              required
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            ></input>
            <div className="error-message">{lastNameError}</div>
          </label>
          <br />
          <label>
            Your Company
            <input
              required
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            ></input>
            <div className="error-message">{companyError}</div>
          </label>
         
       
      </Col>
      <Col>
        <img src={Girl} alt="Girl" className="girl"></img>
      </Col>
    </Row>
    </form>
  );
}
