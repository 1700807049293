import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./app.css";
import SignUp from "./components/SignUp/SignUp";
import UserIntro from "./components/UserIntro";
import Otp from "./components/Otp/Otp";
import Resetpassword from "./components/ResetPassword/Resetpassword";
import Confirmresetpassword from "./components/Confirmresetpassword/Confirmresetpassword";
import Steps from "./components/Steps";
import PaymentConfirmation from "./components/PaymentConfirmation";
import Admin from "./components/Admin/Admin";
import PlanO from "./components/PlanO";
import Plan from "./components/Plan";
import Sidebar from "./components/Sidebar/Sidebar";
import PrivacyPolicy from "./components/PrivacyPolicy/privacy-policy";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SignUp />} />

        <Route path="/WelcomeToDatatram" element={<Sidebar />} />
        <Route path="/Otp" element={<Otp />} />
        <Route path="/Resetpassword" element={<Resetpassword />} />
        <Route
          path="/Confirmresetpassword"
          element={<Confirmresetpassword />}
        />
        <Route path="/WelcomeToDatatram/paymentplans" element={<Steps />} />
        <Route
          path="/WelcomeToDatatram/Paymentprocessed"
          element={<PaymentConfirmation />}
        />
        <Route path="/WelcomeToDatatram/Analytics" element={<Admin />} />
        <Route path="/Planscomparing" element={<PlanO />} />
        <Route path="/paymentplans" element={<Plan />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
};

export default App;
