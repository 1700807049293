import React from 'react'

export default function Analytics() {
  return (
    <div>
      {/* iframe  */}
      <iframe
                      style={{ width: "100%", height: "2000px" }}
                      frameBorder="0"
                      allowFullScreen={true}
                      src={'https://app.powerbi.com/view?r=eyJrIjoiYzMzMjA0YTItMDdjZi00MDQ4LTg0MGItOGUzZWE3N2ZlNGY3IiwidCI6IjQxMGYxMDE4LTI1NjUtNDdhZi04YWViLTQ1MzY2OTg0MWZlNyIsImMiOjl9'}
                      title="PI"
                    />
    </div>
  )
}