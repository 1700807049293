// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-lock{
    color: #38ABD2;
    font-family: Font Awesome 6 Pro;
    font-size: 65px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 49.231% */
    padding-top: 60px;
    padding-bottom: 25px;
}
.Reset-password{
    color: #140054;
font-family: Open Sans;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 32px; /* 133.333% */
padding-top: 10px;
padding-bottom: 15px;
}
.Reset-detail{
    color: #718096;
font-family: Open Sans;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
padding-bottom: 70px;
padding-top: 10px;
text-align: left;
}
.error-message{
    color: var(--error-red, #D21C1C);
font-family: Open Sans;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
input:invalid[focused="true"]{
    border: 1px solid red;
}

/* Resetpassword.css */

.error-message {
    color: red;
  }
  
  .input-error {
    border: 1px solid red;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ResetPassword/Resetpassword.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,+BAA+B;IAC/B,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB,EAAE,YAAY;IAC/B,iBAAiB;IACjB,oBAAoB;AACxB;AACA;IACI,cAAc;AAClB,sBAAsB;AACtB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,iBAAiB,EAAE,aAAa;AAChC,iBAAiB;AACjB,oBAAoB;AACpB;AACA;IACI,cAAc;AAClB,sBAAsB;AACtB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,iBAAiB,EAAE,SAAS;AAC5B,oBAAoB;AACpB,iBAAiB;AACjB,gBAAgB;AAChB;AACA;IACI,gCAAgC;AACpC,sBAAsB;AACtB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,mBAAmB;AACnB;AACA;IACI,qBAAqB;AACzB;;AAEA,sBAAsB;;AAEtB;IACI,UAAU;EACZ;;EAEA;IACE,qBAAqB;EACvB","sourcesContent":[".user-lock{\n    color: #38ABD2;\n    font-family: Font Awesome 6 Pro;\n    font-size: 65px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 32px; /* 49.231% */\n    padding-top: 60px;\n    padding-bottom: 25px;\n}\n.Reset-password{\n    color: #140054;\nfont-family: Open Sans;\nfont-size: 24px;\nfont-style: normal;\nfont-weight: 700;\nline-height: 32px; /* 133.333% */\npadding-top: 10px;\npadding-bottom: 15px;\n}\n.Reset-detail{\n    color: #718096;\nfont-family: Open Sans;\nfont-size: 16px;\nfont-style: normal;\nfont-weight: 400;\nline-height: 24px; /* 150% */\npadding-bottom: 70px;\npadding-top: 10px;\ntext-align: left;\n}\n.error-message{\n    color: var(--error-red, #D21C1C);\nfont-family: Open Sans;\nfont-size: 12px;\nfont-style: normal;\nfont-weight: 400;\nline-height: normal;\n}\ninput:invalid[focused=\"true\"]{\n    border: 1px solid red;\n}\n\n/* Resetpassword.css */\n\n.error-message {\n    color: red;\n  }\n  \n  .input-error {\n    border: 1px solid red;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
