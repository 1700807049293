import { useState } from "react";
import "./formInput.css";

const FormInput = (props) => {
  const [focused, setFocused] = useState(false);
  const { label, errorMessage, onChange, id,type, ...inputProps } = props;

  const handleFocus = (e) => {
    setFocused(true);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="formInput">
      <label>{label}</label>
      <div className="input-container"> 
        <input
        {...inputProps}
        type={showPassword ? "text" : type}
        onChange={onChange}
        onBlur={handleFocus}
        onFocus={() =>
          inputProps.name === "confirmPassword" && setFocused(true)
        }
        focused={focused.toString()}
      />
      {type === "password" && (
          <span
            className="password-toggle-icon"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <i class="fa-solid fa-eye-slash"></i>: <i class="fas fa-eye"></i>}
          </span>
        )}

<span className="span"><i className="fa-solid fa-circle-xmark" style={{color:"red"}}></i> {errorMessage}</span>
     
        
        </div>
     
     
       
     
    </div>
  );
};

export default FormInput;