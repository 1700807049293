import React, { useState } from "react";
import { Button, Steps } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import UserIntro from "../UserIntro";
import Compareplan from "../Compareplan";
import Payment from "../Payment";
import SourceConnector from "../Datafrequency/DataFrequency";
import SelectingFrequency from "../Selectingfrequency/SelectingFrequency";

const { Step } = Steps;

const steps = [
  {
    title: "Basic Information",
    component: UserIntro,
  },
  {
    title: "Choose Plan",
    component: Compareplan,
  },
  {
    title: "Payment Confirmation",
    component: Payment,
  },
  {
    title: "Connect Data Sources",
    component: SourceConnector,
  },
  {
    title: "Data Frequency",
    component: SelectingFrequency,
  },
];

export default function Sidebar() {
  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const buttonStyle = {
    color: "white !important",
  };

  const CurrentComponent = steps[currentStep].component;

  return (
    <div className="row">
      <div className="col-md-3 vh-100">
        <div className="stepbody">
          <div style={{ width: "200px" }}>
            <h3 className="stepheading">{steps[currentStep].title}</h3>
            <Steps
              direction="vertical"
              size="small"
              style={{ fontSize: "10px" }}
              className="custom-step-title span"
            >
              {steps.map((step, index) => (
                <Step
                  key={index}
                  title={step.title}
                  className={index <= currentStep ? "custom-step-current" : ""}
                />
              ))}
            </Steps>

            <div
              style={{
                marginTop: "180px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {currentStep > 0 && (
                <Button className="prebutton" onClick={prevStep}>
                  <span className="button-icon">
                    <ArrowLeftOutlined />
                  </span>
                </Button>
              )}

              {currentStep < steps.length && (
                <Button
                  style={buttonStyle}
                  type="submit"
                  className="nextbutton"
                  onClick={() => {

                    if (currentStep === 0) {
                      document.getElementById("nextBtn").click();
                    } else {
                      nextStep();
                    }
                  }}
                >
                  Next
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-9">

        <CurrentComponent onNext={nextStep} />
      </div>
    </div>
  );
}
