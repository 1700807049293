// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.girl{
    
 width: 300px;
 height: 500px;
 padding-left: 20px;

 padding-top: 200px;

  }

  input
  {
    display: flex;
    border-color: #BAC7D5;
    border-radius: 1px;
    width: 308px;
    display: flex;
padding: 14px 12px;
align-items: center;
gap: 8px;
align-self: stretch;
font-size: 16px;
  }
  .h2design{
    color: #130054;
font-family: sans-serif;
font-size: 36px;
font-style: normal;
font-weight: 700;
line-height: normal;
    padding-top: 50px;
  }
  .submit {
    height: 38px;
    width: 78px;
    color: #ffff !important;
    background-color: #38ABD2;  
    border: none;
    text-align: center; 
    /* font-family: 'sans-serif'; */
    font-weight: normal; 
  }
  .error-message{
    color: red;
    font-size: small;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/UserIntro.css"],"names":[],"mappings":"AAAA;;CAEC,YAAY;CACZ,aAAa;CACb,kBAAkB;;CAElB,kBAAkB;;EAEjB;;EAEA;;IAEE,aAAa;IACb,qBAAqB;IACrB,kBAAkB;IAClB,YAAY;IACZ,aAAa;AACjB,kBAAkB;AAClB,mBAAmB;AACnB,QAAQ;AACR,mBAAmB;AACnB,eAAe;EACb;EACA;IACE,cAAc;AAClB,uBAAuB;AACvB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,mBAAmB;IACf,iBAAiB;EACnB;EACA;IACE,YAAY;IACZ,WAAW;IACX,uBAAuB;IACvB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,+BAA+B;IAC/B,mBAAmB;EACrB;EACA;IACE,UAAU;IACV,gBAAgB;EAClB","sourcesContent":[".girl{\n    \n width: 300px;\n height: 500px;\n padding-left: 20px;\n\n padding-top: 200px;\n\n  }\n\n  input\n  {\n    display: flex;\n    border-color: #BAC7D5;\n    border-radius: 1px;\n    width: 308px;\n    display: flex;\npadding: 14px 12px;\nalign-items: center;\ngap: 8px;\nalign-self: stretch;\nfont-size: 16px;\n  }\n  .h2design{\n    color: #130054;\nfont-family: sans-serif;\nfont-size: 36px;\nfont-style: normal;\nfont-weight: 700;\nline-height: normal;\n    padding-top: 50px;\n  }\n  .submit {\n    height: 38px;\n    width: 78px;\n    color: #ffff !important;\n    background-color: #38ABD2;  \n    border: none;\n    text-align: center; \n    /* font-family: 'sans-serif'; */\n    font-weight: normal; \n  }\n  .error-message{\n    color: red;\n    font-size: small;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
