import React ,{useState} from 'react'
import DpLogo from '../../DpLogo.png'
import NewEmail from '../../images/NewEmail.png'
import OtpInput from 'react-otp-input';
import  './Otp.css'

export default function Otp() {

    const [otp, setOtp] = useState('');

  return (
   <>
     <div className="bg">
     <img src={DpLogo} className="imgdesign" alt="img" />
      <br />
      <br />
      <div className="app">
        <form className="form-design-otp"
        //  onSubmit={handleSubmit}
         >
           <img src={NewEmail} className="new-email" alt="NewEmail" />
           <h2 className='entercode'>Enter The Code</h2>
           <p className='Otp-Detail'>A verification email has  been sent to your email address. Please enter the code below to<br/> continue.</p>
<div >
<OtpInput
  value={otp}
  onChange={setOtp}
  numInputs={6}
  separator={<span style={{ color: "blue" }}>-</span>}
  renderSeparator={<span style={{ color: "blue" }}>-</span>}
  renderInput={(props, index) => (
    <input
      {...props}
      style={{
        margin: '0 5px',
        width: '21px',
        borderRadius: '8px',
        borderColor: '1px solid #C3E6F2',
        textAlign: 'center',
      }}
    />
  )}
/>
</div>
          

        
          <button style={{marginTop:"80px"}}>Verify Account</button>
          <p style={{marginTop:"10px"}}>
          Didn’t receive the email?&nbsp;
            <a href>Resend</a>
          </p>
    

       

          <br />
          <br />
          <br />
        </form>
      </div>
    </div>
   </>
  )
}
