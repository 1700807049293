import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FormInput from "../FormInput";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Divider } from "antd";
import jwt_decode from "jwt-decode";
import DpLogo from "../../images/DpLogo.png";

// document.body.style.overflow = "hidden";

interface InputType {
  id: number;
  name: string;
  type: string;
  errorMessage: string;
  label: string;
  required: boolean;
  pattern?: string;
}

interface DecodedDetails {
  // Define the structure based on the expected decoded token
  [key: string]: any;
}

const SignUp = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [values, setValues] = useState({
    username: "",
    email: "",
    birthday: "",
    password: "",
    confirmPassword: "",
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const inputs: InputType[] = [
    {
      id: 1,
      name: "email",
      type: "email",
      errorMessage: "Incorrect format for email address",
      label: "Email Address",
      required: true,
    },
    {
      id: 2,
      name: "password",
      type: "password",
      errorMessage: "Password is weak",
      label: "Create Password",
      // pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
    },
  ];

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    navigate("/Otp");
  };

  const handlecreateaccount = () => {
    // Add logic for account creation
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleNavigate = () => {
    window.location.href = "/WelcomeToDatatram";
  };

  return (
    <div className="body">
      <img src={DpLogo} className="imgdesign" alt="logo" />

      <div className="app">
        <form className="formdesign" onSubmit={handleSubmit}>
          <h2>
            Meaningful insights to grow <br /> your business
          </h2>
          <br />

          <GoogleOAuthProvider clientId="978459741474-tlc80vcu4ft3bb3gik973q6rq9g32d9j.apps.googleusercontent.com">
            <GoogleLogin
              width="335px"
              // buttonText="Sign up with Google"
              containerProps={{
                className: "custom-google-button",
              }}
              onSuccess={(credentialResponse) => {
                const detail: DecodedDetails = jwt_decode(
                  credentialResponse.credential || ""
                );
                console.log(detail);
                console.log(credentialResponse);
                handleNavigate();
              }}
              onError={() => {
                console.log("Login Failed");
              }}
            />
          </GoogleOAuthProvider>

          <br />
          <Divider className="or ">OR</Divider>
          <br />
          {inputs.map((input) => (
            <FormInput
              className="input-email-password input-style"
              key={input.id}
              {...input}
              value={values[input.name as keyof typeof values]}
              onChange={onChange}
            />
          ))}
          <button
            type="button"
            className="button-style"
            onClick={handlecreateaccount}
          >
            Create Account
          </button>
          <p className="fs-5 ">
            Already have an account?&nbsp;
            <a href="/SignIn">SignIn</a>
          </p>

          <p className="fs-6">
            By creating an account, you agree to our &nbsp;
            <a href="/privacy-policy" className="fs-5">
              Terms and Conditions
            </a>
          </p>

          <br />
          <br />
          <br />
        </form>
      </div>
    </div>
  );
};

export default SignUp;
