import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DpLogo from '../../DpLogo.png';
import userlock from '../../images/userlock.png'
import  './Resetpassword.css'
export default function Resetpassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Incorrect format for email address');
      return false;
    } else {
      setEmailError('');
      return true;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateEmail()) {
      // Continue with form submission or navigation logic
      navigate('/Confirmresetpassword');
    }
  };

  return (
    <>
   <div className="bg">
     <img src={DpLogo} className="imgdesign" alt="img" />
      <br />
      <br />
      <div className="app">
        <form className="form-design-otp"
        //  onSubmit={handleSubmit}
         >
           <img src={userlock} className="user-lock" alt="userlock" />
           <h2 className='Reset-password'>Reset Password</h2>
           <p className='Reset-detail'>Enter your registered email address. You will receive an email to reset your password.</p>
<div >
<label>
           Email Address
            <input
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={emailError ? 'input-error' : ''}
            ></input>
            <div className="error-message">{emailError}</div>
          </label>
</div>
          

        
          <button style={{marginTop:"55px"}} onClick={(e) => handleSubmit(e)}>Send Email</button>
          <p style={{marginTop:"10px"}}>
          Remember your password?&nbsp;
            <a href>Sign In</a>
          </p>
    

       

          <br />
          <br />
          <br />
        </form>
      </div>
    </div>
    </>
  );
}
