import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Steps, Button, Row, Col } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import UserIntro from "./UserIntro";
import Payment from "./Payment";
import PaymentConfirmation from "./PaymentConfirmation";
import DataFrequency from "./Datafrequency/DataFrequency";
import SelectingFrequency from "./Selectingfrequency/SelectingFrequency";

const { Step } = Steps;

interface StepProps {
  title: string;
}

const steps: StepProps[] = [
  { title: "Basic Information" },
  { title: "Choose Plan" },
  { title: "Payment Confirmation" },
  { title: "Connect Data Sources" },
  { title: "Data Frequency" },
];

const stepDescriptions: string[] = [
  "Hello! Welcome to Datatram",
  "Hello! Welcome to Datatram",
  "We offer something for everyone. Choose the plan that speaks your business",
  "We offer something for everyone. Choose the plan that speaks your business",
  "Almost there. Connect to as many data sources as possible.",
  "We can see the finish line. Can you?",
];

const App: React.FC = () => {
  const navigate = useNavigate();

  const handledashboard = () => {
    console.log("done");
    navigate("/WelcomeToDatatram/Analytics");
  };

  const buttonStyle = {
    color: "white !important",
  };

  const [currentStep, setCurrentStep] = useState<number>(2);
  const [userIntro, setUserIntro] = useState<boolean>(false);
  const [comparePlan, setComparePlan] = useState<boolean>(true);
  const [paymentConfirmation, setPaymentConfirmation] =
    useState<boolean>(false);
  const [dataFrequency, setDataFrequency] = useState<boolean>(false);
  const [selectingFrequency, setSelectingFrequency] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);

    if (currentStep === 0) {
      setUserIntro(true);
      setComparePlan(false);
      setPaymentConfirmation(false);
      setDone(false);
      setSelectingFrequency(false);
      setDataFrequency(false);
    } else if (currentStep === 1) {
      setUserIntro(false);
      setPaymentConfirmation(false);
      setDone(false);
      setSelectingFrequency(false);
      setDataFrequency(false);
      setComparePlan(true);
    } else if (currentStep === 2) {
      setUserIntro(false);
      setComparePlan(false);
      setDone(false);
      setSelectingFrequency(false);
      setDataFrequency(false);
      setPaymentConfirmation(true);
    } else if (currentStep === 3) {
      setUserIntro(false);
      setComparePlan(false);
      setDone(false);
      setSelectingFrequency(false);
      setDataFrequency(true);
      setPaymentConfirmation(false);
    } else if (currentStep === 4) {
      setUserIntro(false);
      setComparePlan(false);
      setPaymentConfirmation(false);
      setDataFrequency(false);
      setDone(true);
      setSelectingFrequency(true);
    }
  };

  const handlePrev = () => {
    setCurrentStep((prevStep) => prevStep - 1);

    if (currentStep === 1) {
      navigate("/WelcomeToDatatram");
      setUserIntro(true);
      setDataFrequency(false);
      setComparePlan(false);
      setPaymentConfirmation(false);
      setDone(false);
      setSelectingFrequency(false);
    } else if (currentStep === 2) {
      setUserIntro(false);
      setDataFrequency(false);
      setPaymentConfirmation(false);
      setComparePlan(true);
      setDone(false);
      setSelectingFrequency(false);
    } else if (currentStep === 3) {
      setUserIntro(false);
      setPaymentConfirmation(true);
      setComparePlan(false);
      setDone(false);
      setDataFrequency(false);
      setSelectingFrequency(false);
    } else if (currentStep === 4) {
      setUserIntro(false);
      setPaymentConfirmation(false);
      setComparePlan(false);
      setDataFrequency(true);
      setDone(false);
      setSelectingFrequency(false);
    } else if (currentStep === 5) {
      setUserIntro(false);
      setPaymentConfirmation(false);
      setComparePlan(false);
      setDataFrequency(false);
      setDone(false);
      setSelectingFrequency(true);
    }
  };

  return (
    <>
      <Row>
        <Col span={4}>
          <div
            className="stepbody"
            style={{
              display: "flex",
              alignItems: "center",
              height: "100vh",
              paddingLeft: "35px",
              color: "white",
            }}
          >
            <div style={{ width: "200px" }}>
              <h3 className="stepheading">{stepDescriptions[currentStep]}</h3>
              <Steps
                current={currentStep - 1}
                direction="vertical"
                size="small"
                className="custom-step-title"
              >
                {steps.map((step, index) => (
                  <Step
                    key={index}
                    title={step.title}
                    className={
                      index <= currentStep ? "custom-step-current" : ""
                    }
                  />
                ))}
              </Steps>
              <div
                style={{
                  marginTop: "180px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {currentStep > 0 && (
                  <Button className="prebutton" onClick={handlePrev}>
                    <span className="button-icon">
                      <ArrowLeftOutlined />
                    </span>
                  </Button>
                )}
                {currentStep < steps.length && (
                  <Button
                    style={buttonStyle}
                    className="nextbutton"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                )}
                {done && (
                  <Button
                    style={buttonStyle}
                    className="nextbutton"
                    onClick={handledashboard}
                  >
                    Done
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Col>
        <Col span={20}>
          {comparePlan && <Payment />}
          {paymentConfirmation && <PaymentConfirmation />}
          {dataFrequency && <DataFrequency />}
          {selectingFrequency && <SelectingFrequency />}
        </Col>
      </Row>
    </>
  );
};

export default App;
