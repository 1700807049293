import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Steps, Button, Row, Col } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import UserIntro from "./UserIntro";
import Payment from "./Payment";
import PaymentConfirmation from "./PaymentConfirmation";
import DataFrequency from "./Datafrequency/DataFrequency";
import SelectingFrequency from "./Selectingfrequency/SelectingFrequency";

const { Step } = Steps;

const steps = [
  { title: "Basic Information" },
  { title: "Choose Plan" },
  { title: "Payment Confirmation" },
  { title: "Connect Data Sources" },
  { title: "Data Frequency" },
];

const stepDescriptions = [
  "Hello! Welcome to Datatram",
  "Hello! Welcome to Datatram",
  "We offer something for everyone choose the plan that speaks your business",
  "We offer something for everyone choose the plan that speaks your business",
  "Almost there. Connect to as many data sources as possible.",
  "We can see the finish line. Can you?",
];

const App = () => {
  const navigate = useNavigate();

  const handledashboard = () => {
    console.log("done");

    // Navigate to a different route
    navigate("/WelcomeToDatatram/Analytics");
  };

  const buttonStyle = {
    color: "white !important",
  };

  const [isFormValid, setIsFormValid] = useState(false);

  const handleValidation = (isValid) => {
    setIsFormValid(isValid);
  };

  const validateUserIntro = () => {
    // You don't need to call a function in the child component
    // The validation state is already managed in the parent
    // Do something with the validation result
    if (isFormValid) {
      // Proceed to the next step or perform any other action
    } else {
      // Show an error message or take appropriate action
    }
  };

  const [currentStep, setCurrentStep] = useState(2);

  // const [userIntro, setUserIntro] = useState(true);

  const [comparePlan, setComparePlan] = useState(true);

  const [paymentConfirmation, setpaymentConfirmation] = useState(false);

  const [dataFrequency, setdataFrequency] = useState(false);

  const [selectingFrequency, setselectingFrequency] = useState(false);

  const [done, setdone] = useState(false);

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
    if (currentStep === 0) {
      // setUserIntro(true)
      setComparePlan(false);
      setpaymentConfirmation(false);
      setdone(false);
      setselectingFrequency(false);
      setdataFrequency(false);
      console.log(currentStep);
    }

    if (currentStep === 1) {
      // setUserIntro(false)
      setpaymentConfirmation(false);
      setdone(false);
      setselectingFrequency(false);
      setdataFrequency(false);
      setComparePlan(true);
      console.log(currentStep);
    }

    if (currentStep === 2) {
      // setUserIntro(false)
      setComparePlan(false);
      setdone(false);
      setselectingFrequency(false);
      setdataFrequency(false);
      setpaymentConfirmation(true);
      console.log(currentStep);
    }

    if (currentStep === 3) {
      // setUserIntro(false)
      setComparePlan(false);
      setdone(false);
      setselectingFrequency(false);
      setdataFrequency(true);
      setpaymentConfirmation(false);
      console.log(currentStep);
    }

    if (currentStep === 4) {
      // setUserIntro(false)
      setComparePlan(false);
      setpaymentConfirmation(false);
      setdataFrequency(false);
      setdone(true);
      setselectingFrequency(true);
      console.log(currentStep);
    }
  };

  const handlePrev = () => {
    setCurrentStep((prevStep) => prevStep - 1);

    if (currentStep === 1) {
      navigate("/WelcomeToDatatram");
      // setUserIntro(true)
      setdataFrequency(false);
      setComparePlan(false);
      setpaymentConfirmation(false);
      setdone(false);
      setselectingFrequency(false);

      console.log(currentStep);
    }

    if (currentStep === 2) {
      // setUserIntro(false)
      setdataFrequency(false);
      setpaymentConfirmation(false);
      setComparePlan(true);
      console.log(currentStep);
      setdone(false);
      setselectingFrequency(false);
    }

    if (currentStep === 3) {
      // setUserIntro(false)
      setpaymentConfirmation(true);
      setComparePlan(false);
      setdone(false);
      setdataFrequency(false);
      setselectingFrequency(false);
      console.log(currentStep);
    }

    if (currentStep === 4) {
      // setUserIntro(false)
      setpaymentConfirmation(false);
      setComparePlan(false);
      setdataFrequency(true);
      setdone(false);
      setselectingFrequency(false);
      console.log(currentStep);
    }
    if (currentStep === 5) {
      // setUserIntro(false)
      setpaymentConfirmation(false);
      setComparePlan(false);
      setdataFrequency(false);
      setdone(false);
      setselectingFrequency(true);
      console.log(currentStep);
    }
  };

  return (
    <>
      <Row>
        <Col span={4}>
          <div
            className="stepbody"
            style={{
              display: "flex",
              alignItems: "center",
              height: "100vh",
              paddingLeft: "35px",
              color: "white",
            }}
          >
            <div style={{ width: "200px" }}>
              <h3 className="stepheading"> {stepDescriptions[currentStep]}</h3>
              <Steps
                current={currentStep - 1}
                direction="vertical"
                size="small"
                className="custom-step-title"
              >
                {steps.map((step, index) => (
                  <Step
                    key={index}
                    title={step.title}
                    className={
                      index <= currentStep ? "custom-step-current" : ""
                    }
                    // description={index < currentStep ? 'Verified' : ''}
                  />
                ))}
              </Steps>
              {/* <div style={{ marginTop: '20px' }}>{steps[currentStep].content}</div> */}
              <div
                style={{
                  marginTop: "180px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {currentStep > 0 && (
                  <Button className="prebutton" onClick={handlePrev}>
                    <span className="button-icon">
                      <ArrowLeftOutlined />
                    </span>
                  </Button>
                )}
                {currentStep < steps.length && (
                  <Button
                    style={buttonStyle}
                    className="nextbutton"
                    onClick={() => {
                      handleNext();
                      validateUserIntro();
                    }}
                  >
                    Next
                  </Button>
                )}
                {done && (
                  <Button
                    style={buttonStyle}
                    className="nextbutton"
                    onClick={handledashboard}
                  >
                    Done
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Col>

        <Col span={20}>
          {/* 
     { userIntro&& <UserIntro onValidationChange={handleValidation} />} */}

          {comparePlan && <Payment />}

          {paymentConfirmation && <PaymentConfirmation />}

          {dataFrequency && <DataFrequency />}

          {selectingFrequency && <SelectingFrequency />}

          {}
        </Col>
      </Row>
    </>
  );
};

export default App;
