import React, { useState, useEffect } from "react";
import { Row, Col, Divider, Modal } from "antd";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import standardicon from "../images/standardicon.svg";
import Pro from "../images/Pro.svg";
import Premium from "../images/Premium.svg";
import users from "../images/users.png";
import chart from "../images/chart.png";
import refresh from "../images/refresh.png";
import history from "../images/history.png";
import network from "../images/network.png";
import Group from "../images/Group.png";
import paymentconfirm from "../images/paymentconfirm.png";
import cardback from "../images/cardback.png";

const Table: React.FC = () => {
  const navigate = useNavigate();
  const currentStep = 1; // Your currentStep value

  const linkProps = {
    pathname: "/paymentplans", // Specify the target route
    state: { currentStep }, // Pass the state as an object
  };

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [showOtherContent, setShowOtherContent] = useState<boolean>(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showOtherModal = () => {
    setShowOtherContent(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    return () => {
      console.log("Component will unmount.");
    };
  }, []);

  return (
    <>
      <div>
        <Modal
          title="Pay Now"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          centered
          width={showOtherContent ? 550 : 800}
          wrapClassName="centered-modal"
        >
          {showOtherContent ? (
            <div
              style={{
                display: "grid",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <img
                  src={paymentconfirm}
                  alt="paymentconfirm"
                  style={{
                    marginBottom: "10px",
                    width: "95px",
                    height: "90px",
                    padding: "10px",
                  }}
                />
                <h3 className="chooseplan">Choose Plan</h3>
              </div>

              <h3 className="subscribedplan">
                Your card has been processed! You are now subscribed
              </h3>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <img
                  src={Group}
                  alt="ModalImage"
                  style={{
                    marginBottom: "10px",
                    width: "320px",
                    height: "190px",
                    padding: "10px",
                  }}
                />
                <img
                  src={cardback}
                  alt="ModalImage"
                  style={{
                    marginBottom: "10px",
                    width: "320px",
                    height: "190px",
                    padding: "10px",
                  }}
                />
              </div>
              <button className="paynow" onClick={showOtherModal}>
                Confirm and Pay
              </button>
            </div>
          )}
        </Modal>
        <div style={{ display: "grid" }}>
          {<h1 className="mainheading">Choose a plan for your business</h1>}

          <div style={{ display: "inline-block", paddingTop: "4px" }}>
            <Link className="mainheading" to="/paymentplans">
              Compare Plans
            </Link>
          </div>
        </div>

        <div style={{ display: "flex", paddingLeft: "419px" }}>
          <button className="monthlybtn">Monthly</button>
          <button className="annualbtn">Annually</button>
        </div>

        <Row>
          <Col style={{ paddingLeft: "40px" }} span={8}>
            <div className="zoomable-div">
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={standardicon}
                  className="imagestyle"
                  alt="standardicon"
                />
                <br />
                <div>
                  <h3 className="standard" style={{ paddingBottom: "0px" }}>
                    Standard
                  </h3>
                </div>
                <div>
                  <h1>$50</h1>
                </div>
              </div>

              <Divider className="Divider" />
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  <li>
                    <img src={users} alt="users"></img>&nbsp;&nbsp;&nbsp;&nbsp;5
                    users included
                  </li>
                  <li>
                    <img src={network} alt="users"></img>
                    &nbsp;&nbsp;&nbsp;&nbsp;5 connectors
                    <br />
                    <div style={{ paddingLeft: "35px" }}>
                      <sub className="subtxt">
                        Facebook Ad Account, Google Ad <br /> Manager, Google
                        Analytics, Instagram - Facebook, Shopify/Woo Commerce
                      </sub>
                    </div>
                  </li>
                  <li>
                    <img src={refresh} alt="users"></img>
                    &nbsp;&nbsp;&nbsp;&nbsp;Daily, Weekly, Monthly refresh rate
                  </li>
                  <li>
                    <img src={history} alt="users"></img>
                    &nbsp;&nbsp;&nbsp;&nbsp;No historical data
                  </li>
                  <li>
                    <img src={chart} alt="users"></img>
                    &nbsp;&nbsp;&nbsp;&nbsp;Auto-generated dashboards
                    <div style={{ paddingLeft: "35px" }}>
                      <sub className="subtxt">Metabase</sub>
                    </div>
                  </li>
                </ul>
                <button onClick={showModal} className="planbtn">
                  SUBSCRIBE
                </button>
              </div>
            </div>
          </Col>

          <Col span={8}>
            <div className="zoomable-div">
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={Pro} className="imagestyle" alt="standardicon" />
                <br />
                <div>
                  <h3 className="standard" style={{ paddingBottom: "0px" }}>
                    Pro
                  </h3>
                </div>
                <div>
                  <h1>$100</h1>
                </div>
              </div>

              <Divider className="Divider" />
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  <li>
                    <img src={users} alt="users"></img>
                    &nbsp;&nbsp;&nbsp;&nbsp;10 users included
                  </li>
                  <li>
                    <img src={network} alt="users"></img>
                    &nbsp;&nbsp;&nbsp;&nbsp;7 connectors
                    <br />
                    <div style={{ paddingLeft: "35px" }}>
                      <sub className="subtxt">
                        Facebook Ad Account, Google Ad <br /> Manager, Google
                        Analytics, Instagram - Facebook, Shopify/Woo
                        Commerce+snapchat
                      </sub>
                    </div>
                  </li>
                  <li>
                    <img src={refresh} alt="users"></img>
                    &nbsp;&nbsp;&nbsp;&nbsp;Hourly, Daily, Weekly, Monthly
                    refresh rate
                  </li>
                  <li>
                    <img src={history} alt="users"></img>
                    &nbsp;&nbsp;&nbsp;&nbsp;3 months historical data
                  </li>
                  <li>
                    <img src={chart} alt="users"></img>
                    &nbsp;&nbsp;&nbsp;&nbsp;Auto-generated dashboards
                    <div style={{ paddingLeft: "35px" }}>
                      <sub className="subtxt">PowerBI</sub>
                    </div>
                  </li>
                </ul>
                <button onClick={showModal} className="planbtn">
                  SUBSCRIBE
                </button>
              </div>
            </div>
          </Col>

          <Col span={8}>
            <div className="zoomable-div">
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={Premium} className="premium" alt="standardicon" />
                <br />
                <div>
                  <h3 className="standard" style={{ paddingBottom: "0px" }}>
                    Premium
                  </h3>
                </div>
                <div>
                  <h1>$250</h1>
                </div>
              </div>

              <Divider className="custom-divider" />
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  <li>
                    <img src={users} alt="users"></img>
                    &nbsp;&nbsp;&nbsp;&nbsp;15 users included
                  </li>
                  <li>
                    <img src={network} alt="users"></img>
                    &nbsp;&nbsp;&nbsp;&nbsp;7 connectors(Same as Pro)
                    <br />
                    <div style={{ paddingLeft: "35px" }}>
                      <sub className="subtxt">
                        Facebook Ad Account, Google Ad <br /> Manager, Google
                        Analytics, Instagram - Facebook, Shopify/Woo Commerce
                      </sub>
                    </div>
                  </li>
                  <li>
                    <img src={refresh} alt="users"></img>
                    &nbsp;&nbsp;&nbsp;&nbsp;Hourly, Daily, Weekly, Monthly
                    refresh rate
                  </li>
                  <li>
                    <img src={history} alt="users"></img>
                    &nbsp;&nbsp;&nbsp;&nbsp;6 months historical data
                  </li>
                  <li>
                    <img src={chart} alt="users"></img>
                    &nbsp;&nbsp;&nbsp;&nbsp;Auto-generated dashboards
                    <div style={{ paddingLeft: "35px" }}>
                      <sub className="subtxt">Tableau</sub>
                    </div>
                  </li>
                </ul>
                <button onClick={showModal} className="planbtn">
                  SUBSCRIBE
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Table;
