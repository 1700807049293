import React, { useState } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import standardicon from '../images/standardicon.svg';
import Pro from '../images/Pro.svg';
import Premium from '../images/Premium.svg';
import Circle from '../images/circle.svg';
import greentick from '../images/greentick.svg';
import Group from '../images/Group.png';
import paymentconfirm from '../images/paymentconfirm.png';
import cardback from '../images/cardback.png';
import { Modal } from 'antd';

const Table: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [showOtherContent, setShowOtherContent] = useState<boolean>(false);

  const showModal = (): void => {
    setIsModalVisible(true);
  };

  const showOtherModal = (): void => {
    setShowOtherContent(true);
  };

  const handleCancel = (): void => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div style={{ display: 'grid' }}>
        <Modal
          title="Pay Now"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          centered
          width={showOtherContent ? 550 : 800}
          wrapClassName="centered-modal"
        >
          {showOtherContent ? (
            <div style={{ display: 'grid', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ display: 'flex' }}>
                <img
                  src={paymentconfirm}
                  alt="Payment Confirmation"
                  style={{ marginBottom: '10px', width: '95px', height: '90px', padding: '10px' }}
                />
                <h3 className='chooseplan'>Choose Plan</h3>
              </div>
              <h3 className='subscribedplan'>Your card has been processed! You are now subscribed.</h3>
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ display: 'flex' }}>
                <img
                  src={Group}
                  alt="Group"
                  style={{ marginBottom: '10px', width: '320px', height: '190px', padding: '10px' }}
                />
                <img
                  src={cardback}
                  alt="Card Back"
                  style={{ marginBottom: '10px', width: '320px', height: '190px', padding: '10px' }}
                />
              </div>
              <button className="paynow" onClick={showOtherModal}>
                Confirm and Pay
              </button>
            </div>
          )}
        </Modal>

        <h1 className='mainheading'>Choose a plan for your business</h1>
        <div style={{ display: 'inline-block', paddingTop: '4px' }}>
          <Link className='mainheading' to="/Planscomparing">Plans Overview</Link>
        </div>
      </div>

      <div className='tablediv'>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>
                <div className='container'>
                  <img src={standardicon} className='imagestyle' alt='Standard Plan' />
                  <div style={{ display: 'grid' }}>
                    <div>
                      <h3 className='standard' style={{ paddingBottom: '0px' }}>Standard</h3>
                    </div>
                    <div><h1>$50</h1></div>
                  </div>
                </div>
                <button className='subscribecmp' onClick={showModal}>SUBSCRIBE</button>
              </th>
              <th>
                <div className='container'>
                  <img src={Pro} className='imagestyle' alt='Pro Plan' />
                  <div style={{ display: 'grid' }}>
                    <div>
                      <h3 className='standard' style={{ paddingBottom: '0px' }}>Pro</h3>
                    </div>
                    <div><h1>$100</h1></div>
                  </div>
                </div>
                <button className='subscribecmp' onClick={showModal}>SUBSCRIBE</button>
              </th>
              <th>
                <div className='container'>
                  <img src={Premium} className='imagestyle' alt='Premium Plan' style={{ height: '45px', width: '60px' }} />
                  <div style={{ display: 'grid' }}>
                    <div>
                      <h3 className='standard' style={{ paddingBottom: '0px' }}>Premium</h3>
                    </div>
                    <div><h1>$250</h1></div>
                  </div>
                </div>
                <button className='subscribecmp' onClick={showModal}>SUBSCRIBE</button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='fentry'>Users</td>
              <td>5 users included</td>
              <td>10 users included</td>
              <td>15 users included</td>
            </tr>
            <tr>
              <td className='fentry'>Connectors</td>
              <td>5 Connectors (Facebook Ad Account, Google Ad Manager, Facebook - Instagram Page, Google Analytics, Shopify/Woo Commerce)</td>
              <td>7 Connectors (Standard + Tiktok + Snapchat)</td>
              <td>7 Connectors (Standard + Tiktok + Snapchat)</td>
            </tr>
            <tr>
              <td className='fentry'>Refresh Rate</td>
              <td>Daily, weekly, monthly</td>
              <td>Hourly, daily, weekly, monthly</td>
              <td>Hourly, daily, weekly, monthly</td>
            </tr>
            <tr>
              <td className='fentry'>Historical Data</td>
              <td><img src={Circle} alt="circle" /></td>
              <td>3 months</td>
              <td>3 months, 6 months, 12 months</td>
            </tr>
            <tr>
              <td className='fentry'>Visualization</td>
              <td>Auto-generated dashboards - Metabase</td>
              <td>Auto-generated dashboards - Power BI</td>
              <td>Auto-generated (Power BI) and Self-service dashboard creation via embedded Power BI application</td>
            </tr>
            <tr>
              <td className='fentry'>Scheduled Reports</td>
              <td><img src={Circle} alt="circle" /></td>
              <td><img src={greentick} alt="circle" /></td>
              <td><img src={greentick} alt="circle" /></td>
            </tr>
            <tr>
              <td className='fentry'>User Roles & Permissions</td>
              <td><img src={Circle} alt="circle" /></td>
              <td><img src={greentick} alt="circle" /></td>
              <td><img src={greentick} alt="circle" /></td>
            </tr>
            <tr>
              <td className='fentry'>Alerts</td>
              <td>Activity log (In-app, email)</td>
              <td>Activity log and business insights (In-app, email, Slack)</td>
              <td>Activity log and business insights (In-app, email, Slack)</td>
            </tr>
            <tr>
              <td className='fentry'>Support</td>
              <td>In-app</td>
              <td>In-app</td>
              <td>In-app - Priority response and Expert Consultation (2 hours per month)</td>
            </tr>
            <tr>
              <td className='fentry'>Customer Success Partner</td>
              <td><img src={Circle} alt="circle" /></td>
              <td><img src={Circle} alt="circle" /></td>
              <td><img src={greentick} alt="circle" /></td>
            </tr>
            <tr>
              <td className='fentry'>Dark Mode</td>
              <td><img src={Circle} alt="circle" /></td>
              <td><img src={greentick} alt="circle" /></td>
              <td><img src={greentick} alt="circle" /></td>
            </tr>
            <tr>
              <td className='fentry'>Security</td>
              <td>ISO 27001</td>
              <td>ISO 27001</td>
              <td>ISO 27001</td>
            </tr>
            <tr>
              <td className='fentry'>Setup</td>
              <td>Self-service setup</td>
              <td>Self-service setup</td>
              <td>Self-service setup</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
