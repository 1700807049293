import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "./app.css";
import FormInput from "./components/FormInput";
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Divider } from 'antd';
import jwt_decode from "jwt-decode";
import DpLogo from './DpLogo.png'
import Intro from "./components/Steps";
import Plan from "./components/Plan";
import PaymentConfirmation from "./components/PaymentConfirmation";
import Steps from "./components/Steps";
import Admin from "./components/Admin/Admin";
import PlanO from "./components/PlanO";


import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import UserIntro from "./components/UserIntro";
import Otp from "./components/Otp/Otp";
import Resetpassword from "./components/ResetPassword/Resetpassword";
import Confirmresetpassword from "./components/Confirmresetpassword/Confirmresetpassword";


const WelcomeToDatatram = () => {
  return <Intro />;
};

const SignUp = () => {
  const navigate = useNavigate();
  
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [values, setValues] = useState({
    username: "",
    email: "",
    birthday: "",
    password: "",
    confirmPassword: "",
  });

  const inputs = [
    {
      id: 1,
      name: "Email Address",
      type: "email",
      errorMessage: "Incorrect format for email address",
      label: "Email Address",
      required: true,
    },
    {
      id: 2,
      name: "Create Password",
      type: "password",
      errorMessage:
        "Password are weak",
      label: "Create Password",
      // pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/Otp')
  };

  const handlecreateaccount = () => {
    // navigate('/Otp')
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleNavigate = () => {
    // Perform any necessary actions before navigating
    // ...
    
    // Navigate to the desired route
    window.location.href = '/WelcomeToDatatram';
  };

  return (
    <div className="body">
    
      <img src={DpLogo} className="imgdesign" alt="img" />
      <br />
      <br />
      <div className="app">
        <form className="formdesign" onSubmit={handleSubmit}>
          <h2>Meaningful insights to grow <br></br> your business</h2>
          <br />

          <GoogleOAuthProvider clientId="978459741474-tlc80vcu4ft3bb3gik973q6rq9g32d9j.apps.googleusercontent.com">
        

<GoogleLogin
  width="335px"
  buttonText="Sign up with Google"
  className="custom-google-button"
  onSuccess={(credentialResponse) => {
    const detail = jwt_decode(credentialResponse.credential);
    console.log(detail);
    console.log(credentialResponse);
    handleNavigate();
  }}
  onError={() => {
    console.log('Login Failed');
  }}
/>

          </GoogleOAuthProvider>

          <br />
          <Divider className="or ">OR</Divider>
          <br />
          {inputs.map((input) => (
             <FormInput
            className='input-email-password'
              key={input.id}
              {...input}
              value={values[input.name]}
              onChange={onChange}
            /> 
          ))}
          <button onClick={handlecreateaccount()}>Create Account</button>
          <p style={{marginBottom:"10px"}}>
            Already have an account?&nbsp;
            <a href>SignIn</a>
          </p>
    

          <p style={{fontSize:"11px"}}>
            By creating an account, you agree to our &nbsp;
     
            <a href style={{ fontSize: '12px' }}>Terms and Conditions</a>
          </p>

          <br />
          <br />
          <br />
        </form>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<SignUp />} />
        <Route path="/WelcomeToDatatram" element={<UserIntro />} />
        <Route path="/Otp" element={<Otp />} />
        {/* <Route path="/Otp" element={<Resetpassword />} /> */}
        <Route path="/Confirmresetpassword" element={<Confirmresetpassword/>} />
        <Route path="/WelcomeToDatatram/paymentplans" element={<Steps />} />
        <Route path="/WelcomeToDatatram/Paymentprocessed" element={<PaymentConfirmation />} />
        <Route path="/WelcomeToDatatram/Analytics" element={<Admin />} />
        <Route path="/Planscomparing" element={<PlanO/> } />
        <Route path="/paymentplans" element={<Plan/>} />
      </Routes>
    </Router>
  );
};

export default App;
